<template>
  <div class="client-page p-tb-16 p-lr-20 flex-column">
    <div class="p-tb-10 p-lr-10 add-box">
      <el-button type="primary" size="small" @click="addFn">新建地址(Alt+F3)</el-button>
    </div>
    <el-table
      v-loading="loading"
      ref="addressTable"
      class="table-base m-t-10"
      size="small"
      :data="tableData"
      border
      stripe
      height="400px"
    >
      <el-table-column fixed type="index" label="序号" align="center" width="55"> </el-table-column>
      <el-table-column prop="address" label="收货地址" align="center" min-width="150">
        <template slot-scope="scope">
          <div class="name_box">
            <span>{{ scope.row.address }}</span>
            <div class="create_box" v-if="scope.row.isDefault">
              <svg
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="37px"
                height="39px"
              >
                <g transform="matrix(1 0 0 1 -218 -129 )">
                  <path
                    d="M 19.2594625500287 20.3161234991423  L 0 0  L 37 0  L 37 39  L 19.2594625500287 20.3161234991423  Z "
                    fill-rule="nonzero"
                    fill="#f48305"
                    stroke="none"
                    transform="matrix(1 0 0 1 218 129 )"
                  />
                </g>
              </svg>
              <span class="create">默认</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="receiver" label="收货人" align="center" min-width="100"> </el-table-column>
      <el-table-column prop="phone" label="联系电话" align="center" min-width="150"> </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button size="small" type="text" class="text-danger-btn" @click="deleteFn(scope.row)">
            删除
          </el-button>
          <el-button size="small" type="text" @click="editFn(scope.row)">
            编辑
          </el-button>
          <el-button v-if="!scope.row.isDefault" size="small" type="text" @click="setDefaultFn(scope.row)">
            设为默认地址
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增/编辑收货地址 -->
    <EditAddressBox :visible.sync="editAddressVisible" :curRow="curRow" :isEdit="isEdit" @refresh="initData()" />
  </div>
</template>

<script>
import { getAddresses, deleteAddresses, setDefaultAddresses } from "@/api/addresses";
import EditAddressBox from "./components/EditAddressBox.vue";

export default {
  name: "addressManage", //员工管理
  components: {
    EditAddressBox
  },
  data() {
    return {
      keyName: "alt+f3",
      keyScope: "ManageAddressList",
      loading: false,
      editAddressVisible: false,
      tableData: [],
      curRow: {},
      isEdit: false
    };
  },
  mounted() {
    this.initData();
    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    handleKeyCode(e) {
      switch (e.code) {
        case "F3":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.addFn();
          }
          break;
      }
    },
    //新建地址
    addFn() {
      this.curRow = {};
      this.isEdit = false;
      this.editAddressVisible = true;
    },
    initData() {
      this.loading = true;
      this.tableData = [];
      getAddresses()
        .then(res => {
          this.loading = false;
          this.tableData = res || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteFn(row) {
      this.$confirm(`是否确认删除此收货地址？`, "提示", { type: "warning" })
        .then(() => {
          const data = {
            id: row.id
          };
          deleteAddresses(data)
            .then(() => {
              this.$message.success("删除成功");
              this.initData();
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    editFn(row) {
      this.curRow = { ...row };
      this.isEdit = true;
      this.editAddressVisible = true;
    },
    setDefaultFn(row) {
      const data = {
        id: row.id
      };
      setDefaultAddresses(data)
        .then(() => {
          this.$message.success("设置成功");
          this.initData();
        })
        .catch(() => {
          this.$message.error("设置失败");
        });
    }
  }
};
</script>

<style lang="less" scoped>
.add-box {
  background: #eeeeee;
  border-radius: 4px;
}
/deep/.el-button + .el-button {
  margin-left: 10px;
}
.name_box {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .create_box {
    position: absolute;
    right: -24px;
    top: -8px;
    width: 60px;
    .create {
      position: absolute;
      top: -2px;
      right: 15px;
      font-size: 10px;
      color: #ffffff;
    }
  }
}
</style>
